































import { Button, Cell, CellGroup, Checkbox, CheckboxGroup, Picker, Popup, Search, Sticky, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface Site {
  id: number;
  siteName: string;
}

@Component({
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Sticky.name]: Sticky
  }
})
export default class SitePicker extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop({ default: false }) mutiple!: boolean;
  @Prop({ default: false }) forAccount!: boolean;
  @Prop() businessId!: [number | undefined];
  @Prop({ default: 0 }) defaultId!: number;
  @Prop({ default: 1 }) isShow!: number;
  @Prop() siteList!: { id: number; name: string }[];
  @Prop({ default: 0 }) shareId!: number;
  @Prop({ default: false }) showAll!: boolean;

  list = [] as Site[];
  fullList = [] as Site[];
  loading = true;
  keyword = "";
  defaultIndex = 0;

  checked = [] as Site[];
  currentPicked!: Site;

  get show() {
    return this.value;
  }
  set show(val) {
    this.$emit("input", val);
  }

  @Watch("show", { immediate: true })
  onShow(show: boolean) {
    if (show && this.list.length === 0) {
      this.loadData();
    }
  }
  @Watch("siteList")
  onSiteListChange() {
    this.loadData();
  }
  loadData() {
    if (this.siteList) {
      this.list = this.siteList.map(v => {
        return { id: v.id, siteName: v.name };
      });
      return;
    }
    this.loading = true;
    let requestData = {
      businessId: this.businessId,
      isShow: this.isShow,
      keyword: this.keyword
    };
    let url = "/api/manage/getSiteListForPick";
    if (this.forAccount) {
      url = "/api/manage/account/getSiteListForPick";
      requestData = Object.assign({}, requestData, { shareId: this.shareId });
    }
    this.$axios
      .post(url, requestData)
      .then(res => {
        this.list = res.data.data;
        this.fullList = res.data.data;
        if (this.showAll) {
          this.fullList.unshift({ id: 0, siteName: "全部" });
        }
        if (this.defaultId != 0) {
          this.defaultIndex = this.list.findIndex(v => {
            return this.defaultId == v.id;
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  searchData() {
    this.list = this.fullList.filter(v => v.siteName.indexOf(this.keyword) !== -1);
  }
  onPick() {
    let data;
    if (this.mutiple) {
      data = this.checked.map(v => {
        return {
          id: v.id,
          name: v.siteName
        };
      });
    } else {
      data = {
        id: this.currentPicked.id,
        name: this.currentPicked.siteName
      };
    }
    this.$emit("onPick", data);
    this.show = false;
  }
  @Watch("list") // as the onChange method on picker not actived when list change
  onListChange(list: Site[]) {
    this.changeCurrentPicked(list[0]);
  }
  onChange(sth: never, value: Site) {
    Toast(value.siteName);
    this.changeCurrentPicked(value);
  }
  changeCurrentPicked(value: Site) {
    this.currentPicked = value;
  }
  toggleCheckbox(index: number) {
    const checkboxes = this.$refs.checkboxes as Checkbox[];
    checkboxes[index].toggle();
  }
}
