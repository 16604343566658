



















































































































import "weui";
import weui from "weui.js";
import { Popup, Toast, DatetimePicker } from "vant";
import ListForUser from "./list-for-user.vue";
import moment from "moment";
moment.locale("zh-cn");
import { defineComponent } from "@vue/composition-api";
import SitePicker from "@/components/picker/site-picker.vue";

export default defineComponent({
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    ["list-for-user"]: ListForUser,
    SitePicker
  },
  data() {
    return {
      userPhone: "",
      form: {
        name: "",
        phone: "",
        superTypes: 0,
        type: 1,
        value: 0 as number,
        timeMode: 0,
        timeAfter: 3,
        startTime: moment()
          .startOf("day")
          .toDate(),
        endTime: moment()
          .startOf("day")
          .add(3, "day")
          .toDate(),
        siteId: 0
      },
      formData: {
        siteName: "",
        orderTypeList: [] as number[],
        typeList: [{ label: "优惠金(可多次使用)", value: 1, name: "优惠金" }],
        showStartTimePicker: false,
        showEndTimePicker: false,
        showSitePicker: false
      },
      loading: null,
      showUserCoupon: false
    };
  },
  methods: {
    popupUserCoupon() {
      if ((this.form.phone + "").length != 11) {
        Toast.fail("手机号格式错误");
        return;
      }
      this.userPhone = this.form.phone;
      this.showUserCoupon = true;
    },
    formatTime(m: Date) {
      return moment(m).format("YYYY-MM-DD");
    },
    onPickSite(site: { id: number; name: string }) {
      this.form.siteId = site.id;
      this.formData.siteName = site.name;
    },
    save() {
      this.form.superTypes = this.formData.orderTypeList.reduce((a, b) => {
        return a | b;
      });
      if (!this.form.name || !this.form.value || !this.form.type || !this.form.phone || !this.form.superTypes) {
        weui.alert("内容未完整填写");
        return;
      }
      const data = Object.assign({}, this.form, {
        token: localStorage.token,
        value: this.form.value * 100,
        startTime: this.form.startTime.getTime(),
        endTime: this.form.endTime.getTime() + 86400 * 1000
      });
      this.$axios.post("/api/manage/coupon/giveaway", data).then(() => {
        weui.toast("发放成功");
      });
    }
  }
});
