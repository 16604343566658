


























































import Vue from "vue";
import { List, PullRefresh, Empty, Sticky, Toast, Button, Dialog } from "vant";
import moment from "moment";
moment.locale("zh-cn");

export default Vue.extend({
  props: ["phone"],
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      user: {},
      list: [],

      refreshing: false,
      loading: false,
      empty: false,
      finished: false
    };
  },
  watch: {
    phone: "onPhoneChange"
  },
  methods: {
    onPhoneChange() {
      this.getData();
    },
    onRefresh() {
      this.refreshing = true;
      this.getData().finally(() => {
        this.refreshing = false;
      });
    },
    getData() {
      const data = {
        phone: this.phone
      };
      if ((data.phone + "").length != 11) {
        Toast.fail("手机号格式异常");
        return Promise.reject();
      }
      this.loading = true;
      return this.$axios
        .post("/api/manage/coupon/getListForUser", data)
        .then(res => {
          const data = res.data.data;
          this.user = data.user;
          for (const item of data.list) {
            for (const site of data.siteList) {
              if (item.siteId == site.id) {
                item.siteName = site.name;
              }
            }
          }
          this.list = data.list;
          this.empty = this.list.length == 0;
        })
        .catch(() => {
          this.empty = true;
        })
        .finally(() => {
          this.finished = true;
          this.loading = false;
        });
    },
    disableCoupon(id: number) {
      Dialog.confirm({ message: "确定要停用此卡券吗?" }).then(() => {
        this.$axios.post("/api/manage/coupon/disableCoupon", { couponId: id }).then(() => {
          Toast.success("操作成功");
          this.onRefresh();
        });
      });
    },
    formatTime(time: Date) {
      return moment(time).format("lll");
    }
  }
});
